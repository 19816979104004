<template>
  <div :class="cardClasses" @click="openArticle(url)">
    <div :class="cardClasses">
      <SmartImg
        class="w-full h-[200px] object-cover bg-gray-300 dark:bg-gray-600"
        :src="img"
        :alt="title"
        sizes='304px'
      />
      <div class="px-6 py-4">
        <div class="font-semibold text-xl mb-2 line-clamp-2" v-html="title"></div>
        <p class="text-base text-muted-foreground line-clamp-5" v-html="description"></p>
      </div>
    </div>
  </div>
</template>

<script setup>
import SmartImg from '~/components/SmartImg.vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  img: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
});

const cardClasses = computed(() => {
  return 'max-w-sm rounded-lg overflow-hidden shadow-lg bg-card text-card-foreground cursor-pointer hover:bg-accent hover:text-accent-foreground';
});

const openArticle = async (url) => {
  await navigateTo(url, {
    external: true,
    open: {
      target: '_blank',
    },
  });
};
</script>
