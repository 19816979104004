<template>
  <div>
    <!-- Section Header -->
    <div class="flex justify-between items-center mb-4">
      <div class="ml-4">
        <h2 v-if="objects != undefined" class="text-5xl pb-5">{{ $t('Our travel blog') }}</h2>
        <h2 v-else class="text-5xl pb-5">{{ $t('Learn more') }}</h2>
        <div v-if="cardVersion === 1 && objects != undefined">
          <div v-if="objects?.value?.popular?.length > 0 ?? false" class="flex space-x-4 pb-5">
            <button
              @click="setTab('popular')"
              :class="{
                'text-blue-500 border-b-2 border-blue-500': activeTab === 'popular',
              }"
              class="text-gray-500 dark:text-gray-300 pb-3"
              title="popular-posts"
            >
              {{ $t('Most popular') }}
            </button>
            <button
              @click="setTab('newest')"
              :class="{
                'text-blue-500 border-b-2 border-blue-500': activeTab === 'newest',
              }"
              class="text-gray-500 dark:text-gray-300 pb-3"
              title="newest-posts"
            >
              {{ $t('Newest') }}
            </button>
          </div>
        </div>
      </div>
      <div class="flex justify-between items-center mb-4">
        <div class="flex space-x-2 mr-5 md:mr-0">
          <button @click="scrollLeft" class="bg-white p-2 rounded-full shadow-md" title="arrow-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-gray-800"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button @click="scrollRight" class="bg-white p-2 rounded-full shadow-md" title="arrow-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-gray-800"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- Article Cards -->
    <div class="flex space-x-4 overflow-x-auto pb-10 no-scrollbar" ref="scrollContainer">
      <div v-for="item of articles" :key="item.id" class="w-80 flex-shrink-0">
        <div v-if="cardVersion === 1">
          <ArticleCardV1
            class="ml-4"
            :title="item.title"
            :description="item.excerpt"
            :img="item.featured_image"
            :url="item.link ?? item.slug"
          />
        </div>
        <div v-else-if="cardVersion === 2">
          <ArticleCardV2
            class="ml-4"
            :title="item.title"
            :description="item.excerpt"
            :img="item.featured_image"
            :url="item.link ?? item.slug"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
let articles = ref([]);
const props = defineProps({
  cardVersion: {
    type: Number,
    required: true,
  },
  array: {
    type: Object,
    required: false, //used for 1 array list
  },
  objects: {
    type: Object,
    required: false, //used for 2 array list
  },
});

const activeTab = ref('newest');
const setTab = (tab) => {
  activeTab.value = tab;
  articles.value = props.objects.value[activeTab.value];
};

const scrollContainer = ref(null);
const cardWidth = ref(300);

const scrollLeft = () => {
  const scrollAmount = cardWidth.value * 3;
  scrollContainer.value.scrollBy({
    left: -scrollAmount,
    behavior: 'smooth',
  });
};

const scrollRight = () => {
  const scrollAmount = cardWidth.value * 3;
  scrollContainer.value.scrollBy({
    left: scrollAmount,
    behavior: 'smooth',
  });
};

onMounted(() => {
  console.log(activeTab.value);
  console.log(props.objects);
  if (props.objects && props.objects[activeTab.value]) {
    articles.value = props.objects[activeTab.value];
  } else if (props.array) {
    articles.value = props.array;
  }

  if (props.objects?.value?.popular?.length > 0 ?? false) {
    activeTab.value = 'popular';
  }
});
</script>

<style scoped>
.w-80 {
  width: 20rem; /* 320px, similar to the cards in the screenshot */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
